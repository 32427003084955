import { gql } from "@apollo/client";

import {
  FRAGMENT_FILE,
  FRAGMENT_ENTITY,
  FRAGMENT_COMPANY,
  FRAGMENT_USER,
  FRAGMENT_PROPERTY,
  FRAGMENT_NAMEBOARD,
  FRAGMENT_NAMEBOARD_SETTINGS,
  FRAGMENT_VISIONECT,
  FRAGMENT_ERROR,
  FRAGMENT_ENTITY_DESK,
  FRAGMENT_ENTITY_PARENTS,
  FRAGMENT_ENTITY_BUILDING,
  FRAGMENT_CLIENT_TOKEN,
  FRAGMENT_OUTLOOK_SUBSCRIPTION,
  FRAGMENT_LOG,
  FRAGMENT_TWILIO_CLIENT,
  FRAGMENT_CLIENT_SENDGRID,
  FRAGMENT_CLIENT_SENDGRID_TEMPLATE,
  FRAGMENT_MESSAGE_TEMPLATE,
  FRAGMENT_SCREEN,
  FRAGMENT_OWNER,
  FRAGMENT_EMPLOYEE,
  FRAGMENT_VISITOR,
  FRAGMENT_MEETING,
  FRAGMENT_ACTIVE_DIRECTORY,
  FRAGMENT_ACTIVE_DIRECTORY_SYNC,
  FRAGMENT_CLIENT_PREFERENCES,
  FRAGMENT_SCREEN_SCHEDULE,
  FRAGMENT_MEMBER_GROUP_PREFERENCES,
  FRAGMENT_MEMBER_GROUP,
  FRAGMENT_WAYFINDING_SCREEN,
  FRAGMENT_CURRENT_USER,
  FRAGMENT_ENTRANCE_AGREEMENT,
  FRAGMENT_ENTRANCE_AGREEMENT_FILE,
  FRAGMENT_DIGITAL_RECEPTION_SETTINGS,
  FRAGMENT_MANUAL_REGISTRATION,
  FRAGMENT_MANUAL_REGISTRATION_FIELD,
  FRAGMENT_COURIER_DELIVERY,
  FRAGMENT_MS_TEAMS,
} from "../fragment";

export const QUERY_ENTITIES = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_ENTITIES_OPTIONS = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      ...FragmentEntity
      ...FragmentEntityParents
    }
  }
  ${FRAGMENT_ENTITY}
  ${FRAGMENT_ENTITY_PARENTS}
`;

export const QUERY_ENTITIES_PARENTS = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      id
      title
      type
      properties {
        id
        ... on Company {
          type
          title
        }
      }
      ...FragmentEntityParents
    }
  }
  ${FRAGMENT_ENTITY_PARENTS}
`;

export const QUERY_ENTITY = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_ENTITY_WITH_DESK = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      id
      ... on Building {
        desk {
          id
          email
        }
      }
    }
  }
`;

export const QUERY_COMPANIES = gql`
  query companies(
    $filter: CompanyFilterType
    $sort: CompanySortType
    $pagination: PaginationType
  ) {
    companies(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentCompany
    }
    companiesCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_COMPANY}
`;

export const QUERY_NAMEBOARD_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      title
      entities {
        __typename
        id
        type
        title
        ... on Space {
          id
          title
        }
      }
    }
  }
`;

export const QUERY_COMPANY_NAMEBOARD = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      nameboards {
        id
        title
        items(filter: { property: $id }) {
          id
          entity {
            id
            title
            type
            ...FragmentEntityParents
          }
        }
      }
    }
  }
  ${FRAGMENT_ENTITY_PARENTS}
`;

export const QUERY_COMPANY_VISIONECT = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      visionects {
        id
        items(filter: { property: $id }) {
          id
          entity {
            id
            title
            type
          }
        }
      }
    }
  }
`;

export const QUERY_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const QUERY_PROPERTY_COMPANY = gql`
  query property($id: ID!) {
    property(id: $id) {
      ...FragmentProperty
    }
    propertyPrev(id: $id) {
      ...FragmentProperty
    }
    propertyNext(id: $id) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_PROPERTY_COMPANY_INFORMATION = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        title
        postbox
        country
        city
        address
        zipCode
        description
        emailLanguage
        logo {
          ...FragmentFile
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const QUERY_PROPERTY_COMPANY_CONTACT = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        website
        email
        phone
        fax
      }
    }
  }
`;

export const QUERY_PROPERTY_COMPANY_METADATA = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        metadata
      }
    }
  }
`;

export const QUERY_PROPERTY_COMPANY_SOCIAL = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      type
      ... on Company {
        shortenURL
        facebook
        twitter
        linkedIn
      }
    }
  }
`;

export const QUERY_USER_LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      expiresIn
      requires2fa
    }
  }
`;

export const QUERY_AUTH = gql`
  query auth {
    token @client
    clientId @client
  }
`;

export const QUERY_CLIENTS = gql`
  query clients(
    $filter: ClientFilterType
    $sort: ClientSort
    $pagination: Pagination
  ) {
    clients(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
      code
      plan
      parent {
        id
        title
      }
    }
    clientsCount(filter: $filter)
  }
`;

export const QUERY_CLIENTS_FOR_SIDEBAR = gql`
  query clients(
    $filter: ClientFilterType
    $sort: ClientSort
    $pagination: Pagination
  ) {
    clients(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
    }
  }
`;

// export const QUERY_CURRENT_CLIENT = gql`
//   query client($id: ID!) {
//     clientId @client @export(as: "id")
//     client(id: $id) {
//       id
//       code
//       title
//       email
//       domain
//       plan
//       parent {
//         id
//         title
//       }
//     }
//   }
// `;

export const QUERY_CURRENT_USER = gql`
  query user {
    me {
      ...FragmentCurrentUser
    }
  }
  ${FRAGMENT_CURRENT_USER}
`;

export const QUERY_CLIENT = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      code
      title
      email
      domain
      plan
      parent {
        id
        title
        code
      }
      preferences {
        ...FragmentClientPreferences
      }
    }
  }
  ${FRAGMENT_CLIENT_PREFERENCES}
`;

export const QUERY_CLIENT_PREFERENCES = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      preferences {
        ...FragmentClientPreferences
      }
    }
  }
  ${FRAGMENT_CLIENT_PREFERENCES}
`;

export const QUERY_CLIENT_WITH_TOKEN = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      tokens {
        ...FragmentClientToken
      }
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const QUERY_CLIENT_WITH_OUTLOOK_SUBSCRIPTION = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      outlookSubscriptions {
        ...FragmentOutlookSubscription
      }
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const QUERY_ACTIVE_DIRECTORIES = gql`
  query activeDirectories {
    activeDirectories {
      ...FragmentActiveDirectory
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY}
`;

export const QUERY_ACTIVE_DIRECTORIES_SYNCS = gql`
  query activeDirectorySyncs {
    activeDirectorySyncs {
      ...FragmentActiveDirectorySync
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY_SYNC}
`;

export const QUERY_ACTIVE_DIRECTORIES_SYNC = gql`
  query activeDirectorySync($id: ID!) {
    activeDirectorySync(id: $id) {
      ...FragmentActiveDirectorySync
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY_SYNC}
`;

export const QUERY_ACTIVE_DIRECTORY_GROUPS = gql`
  query activeDirectoryGroups($filter: ActiveDirectoryGroupsFilter!) {
    fetchActiveDirectoryGroups(filter: $filter) {
      id
      displayName
    }
  }
`;

export const QUERY_CLIENT_TOKEN = gql`
  query clientToken($id: ID!, $client: String!) {
    clientToken(id: $id, client: $client) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const QUERY_CLIENT_TOKENS = gql`
  query clientTokens($filter: ClientTokenFilterType) {
    clientTokens(filter: $filter) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const QUERY_CLIENT_SENDGRID_LIST = gql`
  query sendGridClients($filter: SendGridClientFilterInput) {
    sendGridClients(filter: $filter) {
      ...FragmentClientSendGrid
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const QUERY_CLIENT_SENDGRID = gql`
  query sendGridClient($id: ID!) {
    sendGridClient(id: $id) {
      ...FragmentClientSendGrid
      client {
        id
        title
      }
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const QUERY_CLIENT_SENDGRID_TEMPLATES = gql`
  query emailTemplates($filter: EmailTemplateFilterInput!) {
    emailTemplates(filter: $filter) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const QUERY_MESSAGE_TEMPLATES = gql`
  query messageTemplates {
    messageTemplates {
      ...FragmentMessageTemplate
    }
  }
  ${FRAGMENT_MESSAGE_TEMPLATE}
`;

export const QUERY_MESSAGE_TEMPLATE = gql`
  query messageTemplate($id: ID!) {
    messageTemplate(id: $id) {
      ...FragmentMessageTemplate
    }
  }
  ${FRAGMENT_MESSAGE_TEMPLATE}
`;

export const QUERY_CLIENT_SENDGRID_TEMPLATE = gql`
  query emailTemplate($id: ID!) {
    emailTemplate(id: $id) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const QUERY_EMAIL_TEMPLATE_OPTIONS = gql`
  query emailTemplateOptions {
    emailTemplateOptions
    availableSendGridClientTemplates {
      id
      name
    }
  }
`;

export const QUERY_MESSAGE_TEMPLATE_OPTIONS = gql`
  query messageTemplateOptions {
    messageTemplateOptions
    availableMessageTemplateOptions
  }
`;

export const QUERY_USERS = gql`
  query users($filter: UserFilter) {
    users(filter: $filter) {
      ...FragmentUser
      client {
        id
        title
      }
    }
  }
  ${FRAGMENT_USER}
`;

// export const QUERY_NAMEBOARDS = gql`
//   query nameboards(
//     $filter: NameboardFilterType
//     $sort: NameboardSortType
//     $pagination: PaginationType
//   ) {
//     nameboards(filter: $filter, sort: $sort, pagination: $pagination) {
//       ...FragmentScreens
//     }
//   }
//   ${FRAGMENT_SCREENS}
// `;

export const QUERY_CHILDREN_PROPERTIES = gql`
  query entityChildrenProperties($id: ID!) {
    entityChildrenProperties(id: $id) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_ENTITY_PROPERTIES = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      id
      type
      properties {
        ...FragmentProperty
      }
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_PROPERTIES = gql`
  query properties($filter: PropertyFilter) {
    properties(filter: $filter) {
      id
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_VISIONECTS = gql`
  query visionects(
    $filter: VisionectFilterType
    $sort: VisionectSortType
    $pagination: PaginationType
  ) {
    visionects(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const QUERY_MINEW_FILTERED = gql`
  query minew($id: ID!) {
    minew(id: $id) {
      id
      template
      title
      items {
        id
      }
      device {
        id
        name
        mac
      }
      editedBy {
        id
      }
      createdAt
      updatedAt
      previewUrl
    }
  }
`;

export const QUERY_BOARD = gql`
  query nameboard($id: ID!) {
    nameboard(id: $id) {
      ...FragmentNameboard
      playerUrl
      previewUrl
      client {
        id
      }
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const QUERY_BOARD_DATA = gql`
  query nameboard($id: ID!) {
    nameboard(id: $id) {
      id
      title
      template
      playerUrl
      previewUrl
      client {
        id
      }
    }
    nameboardTemplates
  }
`;

export const QUERY_DIGITAL_RECEPTION_TEMPLATES = gql`
  query digitalReceptionTemplates {
    digitalReceptionTemplates
  }
`;

export const QUERY_DIGITAL_RECEPTION = gql`
  query digitalReception($id: ID!) {
    digitalReception(id: $id) {
      id
      title
      template
      playerUrl
      previewUrl
      language
      languages
      code
      client {
        id
      }
      entranceAgreement {
        ...FragmentEntranceAgreement
      }
      building {
        id
        title
      }
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT}
`;

export const QUERY_DIGITAL_RECEPTION_SCHEDULES = gql`
  query digitalReception($id: ID!) {
    digitalReception(id: $id) {
      id
      schedules {
        ...FragmentScreenSchedule
      }
    }
  }
  ${FRAGMENT_SCREEN_SCHEDULE}
`;

export const QUERY_SCREEN_SCHEDULE = gql`
  query screenSchedule($id: ID!) {
    screenSchedule(id: $id) {
      ...FragmentScreenSchedule
    }
  }
  ${FRAGMENT_SCREEN_SCHEDULE}
`;

export const QUERY_DIGITAL_RECEPTION_LANGUAGES = gql`
  query digitalReceptionLanguages($template: DigitalReceptionTemplate!) {
    digitalReceptionLanguages(template: $template)
  }
`;

export const QUERY_DIGITAL_RECEPTION_ACTIVE_LANGUAGES = gql`
  query digitalReception($id: ID!) {
    digitalReception(id: $id) {
      id
      languages
    }
  }
`;

export const QUERY_DIGITAL_RECEPTION_THEME = gql`
  query digitalReception($id: ID!) {
    digitalReception(id: $id) {
      id
      code
      template
      playerUrl
      previewUrl
      theme {
        colors {
          primary
          background
          textPrimary
        }
        images {
          hero {
            id
            filename
            absolutePath
          }
          logo {
            id
            filename
            absolutePath
          }
        }
      }
    }
  }
`;

export const QUERY_NAMEBOARD_ITEM = gql`
  query nameboardItem($id: ID!) {
    nameboardItem(id: $id) {
      id
      sort
      plan {
        id
        path
        absolutePath
      }
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_ITEMS = gql`
  query nameboardItems($filter: NameboardItemsFilter, $pagination: Pagination) {
    nameboardItems(filter: $filter, pagination: $pagination) {
      id
      sort
      entity {
        ...FragmentEntity
      }
    }
    nameboardItemsCount(filter: $filter)
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_PATH = gql`
  query nameboardPath($id: ID!) {
    nameboardPath(id: $id) {
      id
      plan {
        id
        path
        absolutePath
      }
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_PATHS = gql`
  query nameboardPaths($filter: NameboardPathFilter) {
    nameboardPaths(filter: $filter) {
      id
      plan {
        id
        path
        absolutePath
      }
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_NAMEBOARD_TEMPLATES = gql`
  query nameboardTemplates {
    nameboardTemplates
  }
`;

export const QUERY_VISIONECT = gql`
  query visionect($id: ID!) {
    visionect(id: $id) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const QUERY_MINEW = gql`
  query minewDeviceByMac($mac: String!) {
    minewDeviceByMac(mac: $mac) {
      data {
        id
        mac
        screenSize
        battery
        lastupdate
        storeId
        isOnline
        screenInfo {
          inch
          width
          height
          color
        }
      }
    }
  }
`;

export const QUERY_VISIONECT_DEVICES = gql`
  query visionectDevices {
    visionectDevices {
      id
      name
      deviceId
      client {
        id
        title
      }
      deviceData {
        Uuid
        SessionId
        State
        Options {
          Name
        }
        Displays {
          Id
          Rotation
        }
        Status {
          Battery
          ConnectivityUsed
        }
      }
    }
  }
`;

export const QUERY_MINEW_DEVICES = gql`
  query minewDevices {
    minewDevices {
      id
      mac
      name
      storeId
      productId
      client {
        id
      }
      editedBy {
        id
      }
    }
  }
`;

export const QUERY_VISIONECT_DEVICE = gql`
  query visionectDevice($id: ID!) {
    visionectDevice(id: $id) {
      id
      name
      deviceId
      client {
        id
        title
      }
      deviceData {
        Uuid
        State
        Status {
          Battery
          ConnectivityUsed
        }
      }
    }
  }
`;
export const QUERY_MINEWS = gql`
  query minews {
    minews {
      id
      title
      template
      items {
        id
        minew {
          id
          title
          device {
            mac
            id
          }
        }
      }
    }
  }
`;

export const QUERY_MINEW_DEVICE = gql`
  query minewDevice($id: ID!) {
    minewDevice(id: $id) {
      mac
      name
      storeId
      productId
      client {
        id
      }
      editedBy {
        id
      }
    }
  }
`;

export const QUERY_AVAILABLE_VISIONECT_DEVICES = gql`
  query availableVisionectDevices {
    availableVisionectDevices {
      id
      name
      deviceId
    }
  }
`;

export const QUERY_AVAILABLE_MINEW_DEVICES = gql`
  query availableMinewDevices {
    availableMinewDevices {
      id
      name
    }
  }
`;

export const QUERY_VISIONECT_AVAILABLE_TEMPLATES = gql`
  query visionectAvailableTemplates {
    visionectAvailableTemplates
  }
`;

export const QUERY_MINEW_AVAILABLE_TEMPLATES = gql`
  query minewAvailableTemplates {
    minewAvailableTemplates
  }
`;

export const QUERY_VISIONECT_DEVICES_EXTERNAL = gql`
  query visionectDevicesAPI {
    visionectDevicesAPI {
      Uuid
      Options {
        Name
      }
    }
  }
`;

export const QUERY_MINEW_DEVICES_API = gql`
  query minewDevicesAPI {
    minewDevicesAPI {
      id
      name
    }
  }
`;

export const QUERY_VISIONECT_ITEM = gql`
  query visionectItem($id: ID!) {
    visionectItem(id: $id) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_VISIONECT_ITEMS = gql`
  query visionectItems($filter: VisionectItemsFilter) {
    visionectItems(filter: $filter) {
      id
      direction
      entity {
        ...FragmentEntity
      }
      visionect {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_MINEW_ITEM = gql`
  query minewItem($id: ID!) {
    minewItem(id: $id) {
      id
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const QUERY_MINEW_ITEMS = gql`
  query minewItems($filter: MinewItemsFilter) {
    minewItems(filter: $filter) {
      id
      entity {
        id
        title
      }
      minew {
        id
      }
    }
  }
`;

export const QUERY_LOCALE = gql`
  query locale {
    locale @client
  }
`;

export const QUERY_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...FragmentUser
      emailVerified
      emailVerifiedAt
      emailVerificationCodeSentAt
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_ERROR_LOGS = gql`
  query errorLogs(
    $type: LogErrorType!
    $filter: ErrorLogFilter
    $pagination: Pagination
  ) {
    errorLogs(type: $type, filter: $filter, pagination: $pagination) {
      ...FragmentError
    }
    errorLogsCount(type: $type, filter: $filter)
  }
  ${FRAGMENT_ERROR}
`;

export const QUERY_ERROR_LOG = gql`
  query errorLog($id: ID!, $type: LogErrorType!) {
    errorLog(id: $id, type: $type) {
      ...FragmentError
    }
  }
  ${FRAGMENT_ERROR}
`;

export const QUERY_ENTITY_DESK = gql`
  query entityDesk($id: ID!) {
    entityDesk(id: $id) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const QUERY_ENTITY_DESKS = gql`
  query entityDesks($filter: EntityDeskFilterType) {
    entityDesks(filter: $filter) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const QUERY_BUILDINGS = gql`
  query buildings($filter: BuildingFilter) {
    buildings(filter: $filter) {
      ...FragmentEntityBuilding
    }
  }
  ${FRAGMENT_ENTITY_BUILDING}
`;

export const QUERY_BUILDING = gql`
  query building($id: ID!) {
    building(id: $id) {
      ...FragmentEntityBuilding
    }
  }
  ${FRAGMENT_ENTITY_BUILDING}
`;

export const QUERY_OUTLOOK_SUBSCRIPTION = gql`
  query outlookSubscription($id: ID!) {
    outlookSubscription(id: $id) {
      ...FragmentOutlookSubscription
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const QUERY_OUTLOOK_SUBSCRIPTIONS = gql`
  query outlookSubscriptions(
    $filter: OutlookSubscriptionFilterType
    $sort: OutlookSubscriptionSortType
    $pagination: PaginationType
  ) {
    outlookSubscriptions(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      ...FragmentOutlookSubscription
    }
    outlookSubscriptionsCount(
      filter: $filter
      sort: $sort
      pagination: $pagination
    )
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;
export const QUERY_LOGS = gql`
  query logs($type: LogInfoType!, $filter: LogFilter, $pagination: Pagination) {
    logs(type: $type, filter: $filter, pagination: $pagination) {
      ...FragmentLog
    }
    logsCount(type: $type, filter: $filter)
  }
  ${FRAGMENT_LOG}
`;

export const QUERY_LOG = gql`
  query log($id: ID!, $type: LogInfoType!) {
    log(id: $id, type: $type) {
      ...FragmentLog
    }
  }
  ${FRAGMENT_LOG}
`;

export const QUERY_NAMEBOARD_SETTINGS = gql`
  query nameboard($id: ID!) {
    nameboard(id: $id) {
      id
      template
      metadata
      settings {
        ...FragmentNameboardSettings
      }
    }
  }
  ${FRAGMENT_NAMEBOARD_SETTINGS}
`;

export const QUERY_NAMEBOARD_METADATA = gql`
  query nameboard($id: ID!) {
    nameboard(id: $id) {
      id
      metadata
    }
  }
`;

export const QUERY_DIGITAL_RECEPTION_METADATA = gql`
  query digitalReception($id: ID!) {
    digitalReception(id: $id) {
      id
      metadata
    }
  }
`;

export const QUERY_FILES = gql`
  query files(
    $filter: FileFilterType
    $sort: FileSortType
    $pagination: PaginationType
  ) {
    files(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      createdAt
      updatedAt
      filename
      path
      absolutePath
      md5
    }
    filesCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
`;

export const QUERY_CLIENT_TWILIO_CLIENTS = gql`
  query twilioClients {
    twilioClients {
      ...FragmentTwilioClient
    }
  }
  ${FRAGMENT_TWILIO_CLIENT}
`;

export const QUERY_CLIENT_TWILIO_CLIENT = gql`
  query twilioClient($id: ID!) {
    twilioClient(id: $id) {
      ...FragmentTwilioClient
    }
  }
  ${FRAGMENT_TWILIO_CLIENT}
`;

export const QUERY_TWILIO_CLIENT_USAGE = gql`
  query twilioClientUsage($id: ID!, $category: CategoryType!) {
    twilioClientUsage(id: $id, category: $category) {
      count
      countUnit
      price
      priceUnit
      usage
      usageUnit
    }
  }
`;

export const QUERY_TWILIO_CLIENT_LOGS = gql`
  query twilioClientLogs(
    $id: ID!
    $category: CategoryType!
    $filter: TwilioClientUsageFilter
  ) {
    twilioClientLogs(id: $id, category: $category, filter: $filter) {
      logs {
        from
        to
        price
        priceUnit
        duration
        status
        dateCreated
      }
      logsCount
      price
      priceUnit
    }
  }
`;

export const QUERY_SCREENS = gql`
  query screens(
    $filter: ScreenFilterType
    $sort: ScreenSortType
    $pagination: PaginationType
  ) {
    screens(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const QUERY_SCREEN = gql`
  query screen($id: ID!) {
    screen(id: $id) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const QUERY_EMPLOYEE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      ...FragmentEmployee
    }
  }
  ${FRAGMENT_EMPLOYEE}
`;

export const QUERY_EMPLOYEE_PREFERENCES = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      id
      availablePreferences {
        ...FragmentMemberGroupPreferences
      }
      groupPreferences {
        ...FragmentMemberGroupPreferences
      }
      client {
        id
        preferences {
          ...FragmentClientPreferences
        }
      }
    }
  }
  ${FRAGMENT_MEMBER_GROUP_PREFERENCES}
  ${FRAGMENT_CLIENT_PREFERENCES}
`;

export const QUERY_EMPLOYEES = gql`
  query employees(
    $filter: EmployeeFilterType
    $sort: EmployeeSortType
    $pagination: Pagination
  ) {
    employees(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      fullName
      email
      phone
      company {
        id
        title
      }
      externalSource {
        id
        source
      }
    }
    employeesCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
`;

export const QUERY_MEETING = gql`
  query meeting($id: ID!) {
    meeting(id: $id) {
      ...FragmentMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const QUERY_MEETINGS = gql`
  query meetings(
    $filter: MeetingFilterType
    $sort: MeetingSortType
    $pagination: PaginationType
  ) {
    meetings(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      subject
      dtstart
      organizer {
        fullName
        email
      }
      attendeesCount
    }
    meetingsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
`;

export const QUERY_VISITOR = gql`
  query visitor($id: ID!) {
    visitor(id: $id) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;

export const QUERY_VISITORS = gql`
  query visitors(
    $filter: VisitorFilterType
    $sort: VisitorSortType
    $pagination: PaginationType
  ) {
    visitors(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      fullName
      email
      phone
    }
    visitorsCount(filter: $filter)
  }
`;

export const QUERY_CODE_CHECK = gql`
  query fallbackCodeCheck($code: String!) {
    fallbackCodeCheck(code: $code) {
      available
    }
  }
`;

export const QUERY_ACCESS_EVENT = gql`
  query accessEvent($id: ID!) {
    accessEvent(id: $id) {
      id
      type
      metadata
      owner {
        ...FragmentAccessEventOwner
      }
      receiver {
        email
        fullName
      }
      customReceiver {
        email
        fullName
      }
      meeting {
        id
        dtstart
        organizer {
          fullName
          email
        }
      }
      createdAt
    }
  }
  ${FRAGMENT_OWNER}
`;

export const QUERY_ACCESS_EVENTS = gql`
  query accessEvents(
    $filter: AccessEventFilterType
    $sort: AccessEventSortType
    $pagination: Pagination
  ) {
    accessEvents(filter: $filter, sort: $sort, pagination: $pagination) {
      events {
        id
        type
        metadata
        owner {
          ...FragmentAccessEventOwner
        }
        receiver {
          fullName
          email
        }
        customReceiver {
          email
          fullName
        }
        client {
          code
        }
        createdAt
      }
      eventsCount
    }
  }
  ${FRAGMENT_OWNER}
`;

export const QUERY_CHECKED_IN = gql`
  query currentlyCheckedIn(
    $filter: AccessEventFilterType
    $pagination: Pagination
  ) {
    currentlyCheckedIn(filter: $filter, pagination: $pagination) {
      events {
        id
        type
        metadata
        owner {
          ...FragmentAccessEventOwner
        }
        createdAt
      }
      eventsCount
    }
  }
  ${FRAGMENT_OWNER}
`;

export const QUERY_MEETING_ATTENDEE = gql`
  query meetingAttendee($id: ID!) {
    meetingAttendee(id: $id) {
      fullName
      email
    }
  }
`;

export const QUERY_MEMBER_GROUP = gql`
  query memberGroup($id: ID!) {
    memberGroup(id: $id) {
      ...FragmentMemberGroup
    }
  }
  ${FRAGMENT_MEMBER_GROUP}
`;

export const QUERY_MEMBER_GROUPS = gql`
  query memberGroups(
    $filter: MemberGroupFilterType
    $sort: MemberGroupSortType
    $pagination: PaginationType
  ) {
    memberGroups(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
    }
    memberGroupsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
`;

export const QUERY_ENTITIES_DECEASED = gql`
  query entities($filter: EntityFilter) {
    entities(filter: $filter) {
      id
      title
      properties {
        ...FragmentProperty
      }
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_ENTITY_DECEASED = gql`
  query entity($id: ID!) {
    entity(id: $id) {
      id
      title
      properties {
        ...FragmentProperty
      }
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const QUERY_WAYFINDING_SCREEN = gql`
  query wayfindingScreen($id: ID!) {
    wayfindingScreen(id: $id) {
      ...FragmentWayfindingScreen
    }
  }
  ${FRAGMENT_WAYFINDING_SCREEN}
`;

export const QUERY_WAYFINDING_SCREENS = gql`
  query wayfindingScreens($filter: WayfindingScreenFilter) {
    wayfindingScreens(filter: $filter) {
      ...FragmentWayfindingScreen
    }
  }
  ${FRAGMENT_WAYFINDING_SCREEN}
`;

export const QUERY_ACTIVITY = gql`
  query activity($filter: ActivityFilterType) {
    activity(filter: $filter) {
      Employee_checkIn
      Employee_checkOut
      Visitor_checkIn
      Visitor_checkOut
      MmmrDriver_checkIn
      MmmrDriver_checkOut
      MmmrVisitor_checkIn
      MmmrVisitor_checkOut
      MmmrAdditionalVisitor_checkIn
      MmmrAdditionalVisitor_checkOut
      MeetingAttendee_checkIn
      MeetingAttendee_checkOut
      Guest_checkIn
      Guest_checkOut
      Package_checkIn
      Food_checkIn
    }
  }
`;

export const QUERY_ACCESS_EVENT_TYPE_LIST = gql`
  query accessEventTypeList {
    accessEventTypeList
  }
`;

export const QUERY_USER_EMAIL_CHANGE_REQUEST = gql`
  query userEmailForChange($user: ID!) {
    userEmailForChange(user: $user) {
      id
      email
    }
  }
`;

export const QUERY_SCREEN_EVENTS = gql`
  query screenEvents(
    $filter: ScreenEventFilterType
    $sort: ScreenEventSortType
    $pagination: Pagination
  ) {
    screenEvents(filter: $filter, sort: $sort, pagination: $pagination) {
      events {
        id
        type
        value
        createdAt
        preview
        prevEvent {
          ... on ScreenEvent {
            id
            type
            value
            createdAt
          }
        }
      }
      eventsCount
    }
  }
`;

export const QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT = gql`
  query digitalReception($id: ID!) {
    digitalReception(id: $id) {
      id
      entranceAgreement {
        ...FragmentEntranceAgreement
      }
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT}
`;

export const QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE = gql`
  query entranceAgreementFile($id: ID!) {
    entranceAgreementFile(id: $id) {
      ...FragmentEntranceAgreementFile
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT_FILE}
`;

export const QUERY_DIGITAL_RECEPTION_SETTINGS = gql`
  query digitalReception($id: ID!, $language: String) {
    digitalReception(id: $id, language: $language) {
      id
      code
      template
      playerUrl
      previewUrl
      languages
      language
      settings {
        ...FragmentDigitalReceptionSettings
      }
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION_SETTINGS}
`;

export const QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION = gql`
  query manualRegistration($id: ID!) {
    manualRegistration(id: $id) {
      ...FragmentManualRegistration
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION}
`;

export const QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATIONS = gql`
  query manualRegistrations(
    $filter: ManualRegistrationFilterType
    $pagination: Pagination
  ) {
    manualRegistrations(filter: $filter, pagination: $pagination) {
      ...FragmentManualRegistration
    }
    manualRegistrationsCount(filter: $filter)
  }
  ${FRAGMENT_MANUAL_REGISTRATION}
`;

export const QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD = gql`
  query manualRegistrationField($id: ID!, $language: String!) {
    manualRegistrationField(id: $id, language: $language) {
      ...FragmentManualRegistrationField
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION_FIELD}
`;

export const QUERY_COURIER_DELIVERY = gql`
  query courierDelivery($id: ID!) {
    courierDelivery(id: $id) {
      ...FragmentCourierDelivery
    }
  }
  ${FRAGMENT_COURIER_DELIVERY}
`;

export const QUERY_COURIER_DELIVERIES = gql`
  query courierDeliveries(
    $filter: CourierDeliveryFilterType
    $sort: CourierDeliverySortType
    $pagination: PaginationType
  ) {
    courierDeliveries(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentCourierDelivery
    }
    courierDeliveriesCount(
      filter: $filter
      sort: $sort
      pagination: $pagination
    )
  }
  ${FRAGMENT_COURIER_DELIVERY}
`;

export const QUERY_NEDAP_SYNC_LAST_SYNC = gql`
  query NedapSync($input: NedapSyncInput!) {
    nedapLastSync(input: $input) {
      id
      locations
      createdAt
    }
  }
`;

export const QUERY_NEDAP_SYNC_LOCATIONS = gql`
  query NedapSync {
    nedapLocations {
      id
      name
      materializedPath
      locationType
      intramuralLocation
    }
  }
`;

export const QUERY_PROPERTIES_NEDAP_RESIDENTS = gql`
  query Query($filter: PropertyFilter) {
    properties(filter: $filter) {
      ... on NedapResident {
        uuid
        firstName
        lastName
        location {
          name
        }
      }
    }
  }
`;

export const QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS = gql`
  query Query($client: ID!) {
    brotherPrinterTemplateOptions(client: $client) {
      showImage
      showFullName
      showCompany
      showLogo
      showAccessCode
      logo
    }
  }
`;

export const QUERY_MS_TEAMS = gql`
  query teams {
    teams {
      ...FragmentTeams
    }
  }
  ${FRAGMENT_MS_TEAMS}
`;
