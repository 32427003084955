import React, { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { useDebounce } from "react-use";
import { pickBy } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { upload } from "../../../../lib/hooks/useSocket";
import ImageCropPicker from "../../../../components/ImageCropPicker";
import {
  MUTATION_UPDATE_BADGE_TEMPLATE_OPTIONS,
  UPDATE_DIGITAL_RECEPTION_THEME,
} from "../../../../config/graphql/mutation";
import { useClientId } from "../hooks/useClient";
import { QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS } from "../../../../config/graphql/query";

interface IBadgeOptions {
  showImage?: boolean;
  showFullName?: boolean;
  showCompany?: boolean;
  showLogo?: boolean;
  showAccessCode?: boolean;
  logo?: { file: string; filename: string };
}

interface IBadgeOptionsResponse {
  brotherPrinterTemplateOptions: IBadgeOptions & {
    logo: string;
  };
}

const DEFAULT_BADGE_OPTIONS: IBadgeOptions = {
  // showImage: false,
  showFullName: true,
  showCompany: true,
  // showLogo: false,
  // showAccessCode: false,
};

const BadgeTemplateOption = React.memo(
  ({
    name,
    label,
    onChangePreview,
  }: {
    name: string;
    label: string;
    onChangePreview: (name: string, checked: boolean) => void;
  }) => {
    return (
      <Controller
        name={name}
        render={({ field: { value, onChange, ...rest } }) => {
          return (
            <div className="form-check form-check-inline">
              <input
                {...rest}
                id={name}
                type="checkbox"
                className="form-check-input"
                checked={value === true}
                onChange={(e) => {
                  onChange(e);
                  onChangePreview(name, e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor={`${name}`}>
                {label}
              </label>
            </div>
          );
        }}
      />
    );
  },
);

const BadgePrinter: React.FC = () => {
  const { t } = useTranslation(["client", "common"]);
  const clientId = useClientId();

  const badgeIFrame = useRef<HTMLIFrameElement>(null);
  const badgeForm = useForm<IBadgeOptions>({
    shouldUnregister: true,
    defaultValues: DEFAULT_BADGE_OPTIONS,
  });

  const { data } = useQuery<IBadgeOptionsResponse>(
    QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS,
    {
      skip: !clientId,
      variables: { client: clientId },
      onCompleted: (data) =>
        badgeForm.reset(data.brotherPrinterTemplateOptions),
    },
  );

  const badgeFormValues = badgeForm.watch();
  const imageId = data?.brotherPrinterTemplateOptions?.logo || "";

  const [badgeQueryString, setBadgeQueryString] = useState<string>(
    qs.stringify(badgeForm.getValues()),
  );

  useDebounce(
    () => {
      setBadgeQueryString(() =>
        qs.stringify(
          pickBy(
            badgeFormValues,
            (item) => item === true && typeof item === "boolean",
          ),
        ),
      );
    },
    500,
    [badgeFormValues],
  );

  const [onUpdate] = useMutation<{ input: IBadgeOptions & { client: string } }>(
    MUTATION_UPDATE_BADGE_TEMPLATE_OPTIONS,
    {
      refetchQueries: [
        {
          query: QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS,
          variables: { client: clientId },
        },
      ],
    },
  );

  const onUpload = useCallback(async (logo: IBadgeOptions["logo"]) => {
    let input: { logo?: string } = {};

    if (logo?.file) {
      await upload([logo]).then((r) => {
        input = {
          ...input,
          logo: r[0].id,
        };
      });
    }

    return input;
  }, []);

  const onSubmit = useCallback(
    async (values: IBadgeOptions) => {
      const uploadedImages = await onUpload(values?.logo);

      // const theme = {
      //   ...rest,
      //   images: uploadedImages,
      // };

      onUpdate({
        variables: {
          input: {
            client: clientId,
            ...values,
            ...(uploadedImages?.logo ? { logo: uploadedImages?.logo } : {}),
            // id: screenId,
            // theme,
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));

        const previewEl = document.querySelector<HTMLIFrameElement>("#preview");

        if (previewEl) {
          previewEl.src += "";
        }
      });
    },
    [onUpdate, onUpload, t],
  );

  return (
    <div
      className="tab-pane fade show active"
      id="badge-printer"
      role="tabpanel"
    >
      <FormProvider {...badgeForm}>
        <form className="row" onSubmit={badgeForm.handleSubmit(onSubmit)}>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <h5>
                {t(
                  "screens:client.badgePrinter.form.title",
                  "Badge Template Options",
                )}
              </h5>
              <div className="form-group d-flex flex-column">
                <BadgeTemplateOption
                  name="showImage"
                  label={t(
                    "screens:badgePrinter.visitorImage",
                    "Visitor Image",
                  )}
                  onChangePreview={(name, checked) => {
                    console.log(name, checked);
                  }}
                />
                <BadgeTemplateOption
                  name="showFullName"
                  label={t("screens:badgePrinter.visitorName", "Visitor Name")}
                  onChangePreview={(name, checked) => {
                    console.log(name, checked);
                  }}
                />
                <BadgeTemplateOption
                  name="showCompany"
                  label={t(
                    "screens:badgePrinter.visitorCompany",
                    "Visitor Company",
                  )}
                  onChangePreview={(name, checked) => {
                    console.log(name, checked);
                  }}
                />
                <BadgeTemplateOption
                  name="showLogo"
                  label={t(
                    "screens:badgePrinter.hostCompanyLogo",
                    "Host Company Logo",
                  )}
                  onChangePreview={(name, checked) => {
                    console.log(name, checked);
                  }}
                />
                <BadgeTemplateOption
                  name="showAccessCode"
                  label={t("screens:badgePrinter.qrCode", "QR Code")}
                  onChangePreview={(name, checked) => {
                    console.log(name, checked);
                  }}
                />
              </div>
              <Controller
                name="logo"
                render={({ field: { name, value, onChange } }) => (
                  <div className="form-group">
                    <label htmlFor={name}>
                      {t("client:badgePrinter.logo.label", "Logo")}
                    </label>
                    <ImageCropPicker
                      name={name}
                      aspect={1080 / 545}
                      value={value}
                      onChange={(fileBase64) => onChange(fileBase64)}
                    />
                    <small className="form-text text-muted">
                      {t("screens:screen.theme.image.description")}
                    </small>
                  </div>
                )}
              />
            </div>
            {/* <pre>{JSON.stringify(badgeForm.getValues(), null, 2)}</pre> */}
          </div>
          <div className="col-12 col-md-6">
            <iframe
              key={`badge-print-preview?${badgeQueryString}${
                imageId ? `&logo=${imageId}` : ""
              }`}
              ref={badgeIFrame}
              title="badge-print-preview"
              className="border border-dark"
              src={`${process.env.REACT_APP_API_URL}/api/v1/brother-printer/print/?${badgeQueryString}`}
              style={{
                width: "350px",
                height: "212px",
              }}
            />
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(BadgePrinter);
